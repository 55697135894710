import React, {useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import Title from '@components/ui/Title'
import {Form} from 'reactstrap'
import InputText from '@/components/ui/InputText'
import {useTranslation} from 'react-i18next'

const StepName: React.FC = () => {
	const {step, patient, setPatientData, userType, isInReview, setGeneralPatientData} = useBoundStore()
	const {firstName, lastName} = patient
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()
	const [invalidForm, setInvalidForm] = useState({
		firstName: false,
		lastName: false
	})

	const formRef = useRef<HTMLFormElement>(null)

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (firstName && lastName) {
			if (isInReview) {
				setGeneralPatientData('isInDrawer', false)
				setGeneralPatientData('step', 12)
			} else {
				setCommonsData('isNextAnimation', true)
				setGeneralPatientData('step', step + 1)
			}
		} else {
			setInvalidForm({
				firstName: firstName?.trim() === '',
				lastName: lastName?.trim() === ''
			})
		}
	}

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, isName: boolean) => {
		const value = e.target.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ ]+/g, '')
		if (isName) {
			setPatientData('firstName', value)
			value.length > 0 && setInvalidForm({...invalidForm, firstName: false})
		} else {
			setPatientData('lastName', value)
			value.length > 0 && setInvalidForm({...invalidForm, lastName: false})
		}
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title title={t(`${userType}:patientData:fullName:title`)} />
				<Form innerRef={formRef} onSubmit={handleSubmit}>
					<InputText
						label={t(`commons:form:name`)}
						placeholder={t(`commons:form:name`) || ''}
						type="text"
						onChange={e => {
							onChangeHandler(e, true)
						}}
						value={firstName}
						invalid={invalidForm.firstName}
						message={t(`errors:name_error`)}
						autoFocus={!isInReview}
					/>
					<InputText
						label={t(`commons:form:last_name`)}
						placeholder={t(`commons:form:last_name`) || ''}
						type="text"
						onChange={e => {
							onChangeHandler(e, false)
						}}
						value={lastName}
						invalid={invalidForm.lastName}
						message={t(`errors:last_name_error`)}
					/>
				</Form>
			</StepContainer>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepName
