import {useNavigate} from 'react-router-dom'
import React from 'react'
import {StyledContainerButtonNotFound, StyledContainerNotFound} from './styles'
import ButtonCustom from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {ColorfulLogoIcon} from '@assets/icons/icons.tsx'
import {routes} from '@/utilities/constants'
import {resetStores} from '@/store/boundStore'

const Ops: React.FC = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()

	const handleReset = () => {
		resetStores()
		navigate(routes.ONBOARDING.path)
	}

	return (
		<StyledContainerNotFound>
			<div className={'d-flex align-items-center justify-content-center gap-2 pb-4'}>
				<ColorfulLogoIcon />
				<p className={'fw-600'}>{t('commons:landing:icon_text')}</p>
			</div>

			<h2>{t('errors:default')}</h2>

			<StyledContainerButtonNotFound>
				<ButtonCustom type="button" onClick={handleReset}>
					{t('not_found:back_home')}
				</ButtonCustom>
			</StyledContainerButtonNotFound>
		</StyledContainerNotFound>
	)
}

export default Ops
