import axios from '@services/axiosInstance.ts'

export const getCities = (province: string) => {
	return axios.get(`/cities?province__name=${province}`)
}

export const getCardValues = (lang?: string) => {
	return axios.get(`/frontends_sync?language=${lang}`)
}

export const getIubendaContent = () => {
	return axios.get('https://www.iubenda.com/api/termini-e-condizioni/92187305')
}
