import styled, {css} from 'styled-components'

export const StyledContainerTerms = styled.div`
	${({theme}) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: ${theme.spacing * 4}px;
		padding: ${theme.spacing * 2}px ${theme.spacing * 0}px;
	`}
`
