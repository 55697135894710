import {StateCreator} from 'zustand'
import {ClinicalAction, ClinicalInfoDataState} from '@/types/clinicalInfoType.ts'

export const initialState = {
	qrCode: undefined,
	pathologies: [],
	otherPathologies: '',
	medications: [],
	allergies: [],
	otherAllergies: '',
	habits: [],
	showQRCode: false
}

export const createClinicalInfoDataSlice: StateCreator<ClinicalInfoDataState & ClinicalAction> = set => ({
	...initialState,
	setClinicalInfoData: (key, value) => set(() => ({[key]: value}))
})
