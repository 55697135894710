import styled, {css} from 'styled-components'
import {Card, CardTitle} from 'reactstrap'

export const StyledCardTitle = styled(CardTitle)`
	${({theme: {spacing, typography}}) => css`
		${typography.textXl};
		font-weight: 600;
		padding-bottom: ${spacing * 6}px;
	`}
`

export const StyledCardText = styled.p`
	${({theme: {typography}}) => css`
		${typography.textMd};
		font-weight: 400;
	`}
`

export const StyledCard = styled(Card)`
	${({theme: {spacing}}) => css`
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
		border-radius: ${spacing}px;
		height: 400px;
		width: calc(100vw - 32px);
	`}
`

interface ContainerImageProps {
	url: string
}
export const StyledContainerCardImage = styled.div<ContainerImageProps>`
	${({url, theme: {spacing}}) => css`
		border-radius: ${spacing}px ${spacing}px 0 0;
		background-image: url(${url});
		background-size: cover;
		background-position: center;
		min-height: 160px;
		width: 100%;
		& img {
			border-radius: ${spacing}px ${spacing}px 0 0;
		}
	`}
`
