import React, {useEffect, useMemo} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import Footer from '@components/ui/Footer'
import {useTranslation} from 'react-i18next'
import {StyledButtonContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import NextButton from '@components/commons/ButtonTypes/NextButton.tsx'

interface Props {
	formRef?: React.RefObject<HTMLFormElement>
	isLoading?: boolean
	handleSubmit?: () => void
	onPrevHandle?: () => void
}

const StepCta: React.FC<Props> = ({formRef, isLoading, handleSubmit, onPrevHandle}) => {
	const {step, isInReview, setGeneralPatientData, isInDrawer} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()

	const handleOutsideButtonClick = () => {
		if (formRef?.current) {
			formRef?.current.dispatchEvent(new Event('submit', {bubbles: true}))
		}
	}

	const handleNext = () => {
		setGeneralPatientData('step', step + 1)
		setCommonsData('isNextAnimation', true)
	}

	const handlePrev = () => {
		if (onPrevHandle) {
			onPrevHandle()
			setCommonsData('isNextAnimation', false)
		} else {
			if (isInReview && step === 6) {
				setGeneralPatientData('step', 12)
			} else {
				setGeneralPatientData('step', step - 1)
				setCommonsData('isNextAnimation', false)
			}
		}
	}

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault()
				handleOutsideButtonClick()
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [handleOutsideButtonClick, formRef, handleNext])

	const buttonToReturn = useMemo(() => {
		switch (step) {
			case 0:
				return (
					<>
						<StyledButtonStart
							color="primary"
							outline={false}
							type="button"
							onClick={handleOutsideButtonClick}
						>
							{t('commons:start')}
						</StyledButtonStart>
					</>
				)
			case 1:
			case 3:
			case 4:
				return (
					<StyledButtonContainer>
						<BackButton onClick={handlePrev} />
					</StyledButtonContainer>
				)
			case 2:
			case 5:
			case 7:
			case 6:
			case 8:
			case 9:
			case 10:
			case 11:
				return (
					<StyledButtonContainer>
						<BackButton onClick={handlePrev} />
						<NextButton
							onClick={step === 2 ? handleNext : handleOutsideButtonClick}
							isLoading={isLoading}
						/>
					</StyledButtonContainer>
				)
			case 12:
				return (
					<>
						{isInDrawer ? (
							<StyledButtonContainer>
								<div />
								{formRef && (
									<NextButton
										onClick={handleOutsideButtonClick}
										className={'w-100'}
										isLoading={isLoading}
										text={'commons:confirm'}
									/>
								)}
							</StyledButtonContainer>
						) : (
							<StyledButtonContainer>
								<BackButton
									onClick={() => {
										setGeneralPatientData('isInReview', false)
										handlePrev()
									}}
								/>
								<NextButton onClick={handleSubmit} isLoading={isLoading} />
							</StyledButtonContainer>
						)}
					</>
				)
			default:
				break
		}
	}, [step, t])

	return <Footer>{buttonToReturn}</Footer>
}
export default StepCta
