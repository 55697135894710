import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {PatientDataAction, PatientDataState} from '../types/patientType.ts'
import {CommonsAction, CommonsDataState} from '../types/commonType.ts'
import {initialState as commonInitialState, createCommonsDataSlice} from './slices/commonDataSlice'
import {initialState as patientInitialState, createPatientDataSlice} from './slices/patientDataSlice'
import {initialState as clinicalInitialState, createClinicalInfoDataSlice} from './slices/clinicalInfoDataSlice'
import {initialState as symptomsInitialState, createSymptomsDataSlice} from './slices/symptomsDataSlice'
import {SymptomsAction, SymptomsDataState} from '../types/symptomType.ts'
import {ClinicalAction, ClinicalInfoDataState} from '../types/clinicalInfoType.ts'

export const useBoundStore = create<
	PatientDataState & PatientDataAction & ClinicalInfoDataState & ClinicalAction & SymptomsDataState & SymptomsAction
>()(
	persist(
		(...a) => ({
			...createPatientDataSlice(...a),
			...createClinicalInfoDataSlice(...a),
			...createSymptomsDataSlice(...a)
		}),
		{name: 'bound-store'}
	)
)

export const useCommonsStore = create<CommonsDataState & CommonsAction>()(
	persist(
		(...a) => ({
			...createCommonsDataSlice(...a)
		}),
		{name: 'common-store'}
	)
)

export const resetStores = () => {
	useBoundStore.setState({
		...patientInitialState,
		...clinicalInitialState,
		...symptomsInitialState
	})
	useCommonsStore.setState(commonInitialState)
}
