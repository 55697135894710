import {useMemo} from 'react'
import {CommonItem, CommonsAction, CommonsDataState} from '@/types/commonType.ts'
import {COLORS_CODE} from '@utilities/constants'

/*types*/

interface remapSelectOptionsProps {
	id?: number
	name: string
}

interface City {
	id: number
	province: {
		name: string
	}
	name: string
	istat_code: string
}

export interface AxiosError {
	response?: {
		status: number
		data: {[key: string]: string}
	}
	request?: object
	message: string
}

interface handleGenericErrorProps {
	error: AxiosError
	setCommonsData: CommonsAction['setCommonsData']
	t: (key: string) => string
}

/*function*/

export const IsItaly = {value: '105', label: 'Italia'}

export const handleGenericError = ({error, setCommonsData, t}: handleGenericErrorProps) => {
	if (!error.response) {
		return
	}

	const handleErrorMessage = (message: string, status: CommonsDataState['showToast']['status'] = 'danger') => {
		setCommonsData('showToast', {
			isVisible: true,
			text: message,
			status: status
		})
	}

	const errorData = error.response.data
	if (Array.isArray(errorData)) {
		errorData.forEach(errorMessage => handleErrorMessage(errorMessage))
	} else if (typeof errorData === 'object') {
		Object.keys(errorData).forEach(key => {
			const errorMessages = errorData[key]
			if (Array.isArray(errorMessages)) {
				errorMessages.forEach(errorMessage => handleErrorMessage(errorMessage))
			} else if (typeof errorMessages === 'object') {
				const message = Object.values(errorMessages)[0]
				handleErrorMessage(message as string)
			} else {
				handleErrorMessage(errorMessages as string)
			}
		})
	} else {
		handleErrorMessage(t('errors:default') || '')
	}
}

export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const hexColorFromBootstrap = (codeType: number) => {
	switch (codeType) {
		case 1:
			return COLORS_CODE.red
		case 2:
			return COLORS_CODE.orange
		case 3:
			return COLORS_CODE.blue
		case 4:
			return COLORS_CODE.green
		case 5:
			return COLORS_CODE.white
		default:
			null
	}
}

export const remapSelectOptions = (array: remapSelectOptionsProps[] | undefined | null) => {
	return (
		array?.map(({name, id}) => ({
			value: id ? id?.toString() : name,
			label: name
		})) || []
	)
}

export const useCitiesList = (cities: City[]) => {
	return useMemo(() => {
		if (cities && cities.length > 0) {
			return cities.map(({name, id, ...rest}) => ({
				label: name,
				value: id ? id?.toString() : name,
				data: rest.istat_code
			}))
		} else {
			return []
		}
	}, [cities])
}

export const seventeenYearsAgo = () => {
	const date = new Date()
	date.setFullYear(date.getFullYear() - 17)
	return date.toISOString().split('T')[0]
}

export const minDate = () => {
	const date = new Date()
	date.setFullYear(date.getFullYear() - 130)
	return date.toISOString().split('T')[0]
}

export const languagesList = (languages: {[key: string]: string}) => {
	return Object.entries(languages).map(([value, label]) => ({value, label}))
}

export const handleTranslatedValue = (data: CommonItem[] | undefined, id: number | string | undefined) => {
	return data?.find(i => i.id.toString() === id?.toString())?.name || '-'
}
