import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {PHASES, routes} from '@/utilities/constants'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {useEffect} from 'react'

type Condition = {
	phase: string
	path: string
}
const PrivateRoute = () => {
	const navigate = useNavigate()

	const {step, qrCode} = useBoundStore()
	const {userPhase, cardsData} = useCommonsStore()
	const {pathname} = useLocation()
	const isOther = userPhase === PHASES.other

	const isTermsPath = pathname === routes.TERMS.path
	const isPrivacyPath = pathname === routes.PRIVACY.path

	useEffect(() => {
		if (step > 2 && !cardsData) {
			setTimeout(() => {
				navigate(routes.NOTFOUND.path)
			}, 1500)
		}
	}, [step, cardsData])

	if (isTermsPath || isPrivacyPath) {
		return <Outlet />
	}

	const conditions = [
		{phase: PHASES.clinicalInfo, path: routes.CLINICALINFO.path},
		{phase: PHASES.symptoms, path: routes.SYMPTOMS.path},
		{phase: PHASES.finished, path: routes.FINISHED.path},
		{phase: PHASES.triageConcluded, path: routes.TRIAGE_CONCLUDED.path}
	]

	const otherPathsAllowed = [
		routes.CODEASSIGNED.path,
		routes.CODE.path,
		routes.ENTERED_INFORMATION.path,
		routes.FEEDBACK.path
	]

	const redirectToPhase = (condition: Condition) => {
		if (pathname !== condition.path && userPhase === condition.phase) {
			return <Navigate to={condition.path} />
		}
		return null
	}

	for (const condition of conditions) {
		const redirect = redirectToPhase(condition)
		if (redirect) {
			return redirect
		}

		if (
			qrCode &&
			pathname !== routes.CODEASSIGNED.path.replace(':id', qrCode) &&
			isOther &&
			!otherPathsAllowed.includes(pathname)
		) {
			return <Navigate to={routes.CODEASSIGNED.path.replace(':id', qrCode)} />
		}
	}

	return !qrCode ? <Navigate to={routes.ONBOARDING.path} /> : <Outlet />
}

export default PrivateRoute
