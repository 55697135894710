export const USER_TYPES = {
	caregiver: 'caregiver',
	patient: 'patient'
}

export const GENDER_TYPES = {
	female: 2,
	male: 1
}

export const CITY_TYPES = {
	residence: 'residence',
	domicile: 'domicile'
}

export const WORK_TYPES = {
	employed: 'Occupato',
	unemployed: 'Disoccupato'
}

export const QR_TYPES = {
	red: 1,
	orange: 2,
	blue: 3,
	green: 4,
	white: 5
}

export const LANGUAGES = [
	{value: 'it', label: 'Italiano'},
	{value: 'es', label: 'Español'},
	{value: 'fr', label: 'Français'},
	{value: 'de', label: 'Deutsch'},
	{value: 'en', label: 'English'},
	{value: 'pt', label: 'Português'}
]

export const PHASES = {
	clinicalInfo: 'clinical-info',
	symptoms: 'symptoms',
	finished: 'finished',
	other: 'other',
	triageConcluded: 'triage-concluded'
}

export const COLORS_CODE = {
	red: '#CC334D',
	orange: '#E0A243',
	blue: '#4392E0',
	green: '#329574',
	white: '#fff'
}
