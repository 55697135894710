import {HeaderWrapper, StyledHeaderSpinner} from '@components/commons/Header/style.ts'
import {LogoIcon} from '@assets/icons/icons.tsx'
import Dropdown from '@components/ui/Dropdown'
import {FC, useEffect, useMemo, useState} from 'react'
import Avatar from '@components/ui/Avatar'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import {LANGUAGES, routes} from '@utilities/constants'
import {resetStores, useBoundStore, useCommonsStore} from '@/store/boundStore'
import {getCardValues} from '@services/commons.http.ts'
import {AxiosError, handleGenericError, languagesList} from '@utilities/helpers.ts'
import {Spinner} from 'reactstrap'

interface HeaderProps {
	hasAvatar?: boolean
}

const Header: FC<HeaderProps> = ({hasAvatar}) => {
	const {t, i18n} = useTranslation()
	const navigate = useNavigate()
	const {cardsData, setCommonsData} = useCommonsStore()
	const defaultLanguage = localStorage.getItem('i18nextLng')
	const {pathname} = useLocation()
	const {qrCode} = useBoundStore()

	const [language, setLanguage] = useState<string>('it')
	const [isLoading, setIsLoading] = useState(false)

	const languages = useMemo(() => {
		return cardsData?.settings?.languages ? languagesList(cardsData?.settings?.languages) : LANGUAGES
	}, [cardsData])

	const languagesRemap = languages.map(item => {
		return {
			value: item.value,
			label: item.label.toUpperCase()
		}
	})

	const handleReset = () => {
		resetStores()
		navigate(routes.ONBOARDING.path)
	}

	const isStepTriageConcluded = pathname?.includes(routes.TRIAGE_CONCLUDED.path)
	const isCodeAssignedPath = qrCode && pathname?.includes(routes.CODEASSIGNED.path.replace(':id', qrCode))

	const dropdownItemsPrivacy = [
		{
			value: routes.TERMS.path,
			label: t('commons:terms:title')
		},
		{
			value: routes.PRIVACY.path,
			label: t('commons:privacy:title')
		},
		{
			value: routes.ONBOARDING.path,
			label: t('commons:again:title')
		}
	]

	const dropdownItemsAvatar = [
		{
			value: routes.ENTERED_INFORMATION.path,
			label: t('commons:info:entered_information')
		},
		{
			value: routes.CODE.path,
			label: t('commons:code:triage_code')
		},
		...dropdownItemsPrivacy
	]

	const dropdownItemsToShow = isStepTriageConcluded || isCodeAssignedPath ? dropdownItemsAvatar : dropdownItemsPrivacy

	useEffect(() => {
		if (defaultLanguage !== null) {
			setLanguage(defaultLanguage)
		}
	}, [])

	const handleCardValues = async (lang: string) => {
		setIsLoading(true)
		try {
			const {data} = await getCardValues(lang)
			setCommonsData('cardsData', data)
			setCommonsData('provinces', data.patient_data?.provinces)
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleChangeLanguage = (value: string) => {
		setLanguage(value)
		handleCardValues(value)
		i18n?.changeLanguage(value)
	}
	return (
		<HeaderWrapper className={'bg-primary text-white p-3'}>
			<div className={'d-flex justify-content-between align-items-center h-100'}>
				<LogoIcon />
				<div className={'d-flex justify-content-end align-items-center h-100 gap-3'}>
					<Dropdown
						dropdownItems={languagesRemap}
						onClickItems={value => handleChangeLanguage(value)}
						stringToShow={language?.toUpperCase()}
					/>
					{hasAvatar && (
						<Dropdown
							dropdownItems={dropdownItemsToShow}
							onClickItems={value => {
								if (value === routes.ONBOARDING.path) {
									handleReset()
								} else {
									setCommonsData('isNextAnimation', true)
									navigate(value)
								}
							}}
						>
							<Avatar />
						</Dropdown>
					)}
				</div>
			</div>
			{isLoading && (
				<StyledHeaderSpinner>
					<LogoIcon />
					<Spinner />
				</StyledHeaderSpinner>
			)}
		</HeaderWrapper>
	)
}

export default Header
