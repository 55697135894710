import React, {useEffect} from 'react'
import {useBoundStore} from '@/store/boundStore'

import StepOnBoarding from './steps/StepOnBoarding.tsx'
import StepAssistance from './steps/StepAssistance.tsx'
import StepInfo from './steps/StepInfo.tsx'
import StepReason from './steps/StepReason.tsx'
import StepTime from './steps/StepTime.tsx'
import StepName from './steps/StepName.tsx'
import StepCF from './steps/StepCF.tsx'
import StepRegistry from './steps/StepRegistry.tsx'
import StepResidence from './steps/StepResidence.tsx'
import StepPhone from './steps/StepPhone.tsx'
import StepOtherPhone from './steps/StepOtherPhone.tsx'
import StepOtherInfo from './steps/StepOtherInfo.tsx'
import StepReview from './steps/StepReview.tsx'

const stepComponents = [
	StepOnBoarding,
	StepAssistance,
	StepInfo,
	StepReason,
	StepTime,
	StepName,
	StepCF,
	StepRegistry,
	StepResidence,
	StepPhone,
	StepOtherPhone,
	StepOtherInfo,
	StepReview
]

const OnBoarding: React.FC = () => {
	const {step, setGeneralPatientData} = useBoundStore()

	useEffect(() => {
		setGeneralPatientData('step', step)
		setGeneralPatientData('isInReview', false)
	}, [])

	const CurrentStep = stepComponents[step] || StepOnBoarding

	return <CurrentStep />
}

export default OnBoarding
