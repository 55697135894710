import {StateCreator} from 'zustand'
import {Patient, PatientDataAction, PatientDataState} from '@/types/patientType.ts'
import {IsItaly} from '@/utilities/helpers'

const initialPatientState = {
	patientId: null,
	cardId: null,
	firstName: '',
	lastName: '',
	hasCF: true,
	noCFItaly: true,
	isResident: true,
	isItalyDomicile: true,
	cf: '',
	birthDate: undefined,
	gender: undefined,
	birthProvince: undefined,
	birthPlace: undefined,
	birthNation: IsItaly,
	birthNiCity: '',
	maritalStatus: undefined,
	residenceAddress: '',
	residenceNiAddress: '',
	residenceProvince: undefined,
	residenceCity: undefined,
	residenceCap: undefined,
	residenceNation: IsItaly,
	residenceNiCity: '',
	residenceNiCap: '',
	residenceIsDomicile: true,
	domicileAddress: '',
	domicileProvince: undefined,
	domicileCity: undefined,
	domicileCap: undefined,
	domicileNation: IsItaly,
	domicileNiAddress: '',
	domicileNiCity: '',
	domicileNiCap: '',
	phoneNumberPrefix: {label: 'IT (+39)', value: 'IT (+39)'},
	phoneNumber: undefined,
	sms: false,
	otherPhoneNumberPrefix: {label: 'IT (+39)', value: 'IT (+39)'},
	otherPhoneNumber: undefined,
	otherSms: undefined,
	qualification: undefined,
	workCondition: undefined,
	position: undefined,
	activity: undefined,
	isCalculatedCF: false
}

export const initialState = {
	step: 0,
	subStep: 6,
	colorCode: null,
	isInDrawer: false,
	isInReview: false,
	terms: false,
	triageStarted: new Date(),
	userType: undefined,
	whatHappened: undefined,
	howLong: undefined,
	patient: initialPatientState,
	gipseUpdated: ''
}

export const createPatientDataSlice: StateCreator<PatientDataState & PatientDataAction> = set => ({
	...initialState,
	//COMMON
	setGeneralPatientData: (key, value) => set(() => ({[key]: value})),
	//PATIENT
	setPatientData: (key, value) => set(state => ({patient: {...state.patient, [key]: value}})),
	setPatientMultiData: (data: Patient) => set(state => ({patient: {...state.patient, ...data}}))
})
