import {StepContainer, StyledContainerIconStep2} from '@pages/onBoarding/styles.ts'
import {useTranslation} from 'react-i18next'
import {BluCodeIcon, GreenCodeIcon, OrangeCodeIcon, RedCodeIcon, WhiteCodeIcon} from '@assets/icons/icons.tsx'
import {useNavigate} from 'react-router-dom'
import CardOption from '@components/ui/CardOption'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import {useMemo} from 'react'

const CodeExplanation = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {userType, colorCode} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()

	const codes = useMemo(() => {
		return cardsData?.triage_codes
	}, [cardsData])

	//TODO: get numberCode or color from store
	const numberCode = colorCode

	const codeIcon = (id: number) => {
		switch (id) {
			case 1:
				return <RedCodeIcon />
			case 2:
				return <OrangeCodeIcon />
			case 3:
				return <BluCodeIcon />
			case 4:
				return <GreenCodeIcon />
			case 5:
				return <WhiteCodeIcon />
			default:
				return <WhiteCodeIcon />
		}
	}

	return (
		<>
			<div className={'flex-column d-flex justify-content-start align-items-start p-3 pt-4 bg-white'}>
				<BackButton
					text={'commons:back'}
					onClick={() => {
						setCommonsData('isNextAnimation', false)
						navigate(-1)
					}}
					className={'bg-white'}
				/>
			</div>
			<StepContainer className={'h-100 rounded-0 border-0'}>
				<div className={'flex-column d-flex justify-content-start align-items-start pt-2'}>
					<h1>{t('commons:code:triage_code')}</h1>
				</div>

				<div className={'h-100 overflow-scroll'}>
					{codes?.map(item => {
						return (
							<div className={'border-bottom'} key={item.id}>
								<CardOption
									title={t(`${item.name}`)}
									info={t(`${item.wait_time_description}`)}
									subtitle={t(`${item.description}`)}
									withBorder={false}
									icon={<StyledContainerIconStep2>{codeIcon(item.id)}</StyledContainerIconStep2>}
									havePadding={false}
									haveChip={numberCode === item.id}
									chipChildren={<p>{t(`${userType}:waiting:assignedCode:code`)}</p>}
								/>
							</div>
						)
					})}
				</div>
			</StepContainer>
		</>
	)
}

export default CodeExplanation
