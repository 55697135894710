import React, {useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {Form} from 'reactstrap'
import {StepContainer} from '../styles.ts'
import Title from '@components/ui/Title'
import card1 from '@/assets/images/card1.png'
import card2 from '@/assets/images/card2.png'
import card3 from '@/assets/images/card3.png'
import card4 from '@/assets/images/card4.png'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {Trans, useTranslation} from 'react-i18next'
import CommonModal from '@components/commons/Modal/index.tsx'
import CustomCarousel from '@/components/commons/Carousel/index.tsx'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'
import {getCardValues} from '@/services/commons.http.ts'

const StepOnBoarding: React.FC = () => {
	const {step, setGeneralPatientData} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()
	const defaultLanguage = localStorage.getItem('i18nextLng') || 'it'

	const [modal, setModal] = useState(false)

	const formRef = useRef<HTMLFormElement>(null)

	const handleCardValues = async (lang: string) => {
		try {
			const {data} = await getCardValues(lang)
			setCommonsData('cardsData', data)
			setCommonsData('provinces', data.patient_data?.provinces)
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		setModal(true)
	}

	const handleConfirm = () => {
		setCommonsData('isNextAnimation', true)
		handleCardValues(defaultLanguage)
		setGeneralPatientData('terms', true)
		setGeneralPatientData('triageStarted', new Date())
		setGeneralPatientData('step', step + 1)
	}

	const items = [
		{
			id: 1,
			src: card1,
			title: t('commons:welcome_page:card_1:title'),
			text: t('commons:welcome_page:card_1:text')
		},
		{
			id: 2,
			src: card2,
			title: t('commons:welcome_page:card_2:title'),
			text: t('commons:welcome_page:card_2:text')
		},
		{
			id: 3,
			src: card3,
			title: t('commons:welcome_page:card_3:title'),
			text: t('commons:welcome_page:card_3:text')
		},
		{
			id: 4,
			src: card4,
			title: t('commons:welcome_page:card_4:title'),
			text: t('commons:welcome_page:card_4:text')
		}
	]

	return (
		<>
			<StepContainer step0 notAnimate>
				<Title label={t('commons:welcome_page:welcome')} title={t('commons:welcome_page:title')} />
				<CustomCarousel items={items} />
			</StepContainer>

			<Form innerRef={formRef} onSubmit={handleSubmit} className={'m-0'} />
			<CommonModal
				title={t('commons:welcome_page:consent') || ''}
				modal={modal}
				setModal={setModal}
				centered
				confirm={t('commons:authorize') || ''}
				onConfirm={handleConfirm}
				cancel={t('commons:refuse') || ''}
				onCancel={() => setModal(false)}
			>
				<p>
					<Trans
						i18nKey={t('commons:welcome_page:terms')}
						components={{a: <a onClick={() => setCommonsData('isNextAnimation', true)} />}}
					/>
				</p>
			</CommonModal>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepOnBoarding
