import {useBoundStore} from '@/store/boundStore'
import React, {useState} from 'react'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import StepClinicalInfoCta from '@pages/clinicalInfo/steps/StepClinicalInfoCta.tsx'
import Alert from '@components/ui/Alert'
import {StepContainer, StyledButtonAddDrug} from '@pages/onBoarding/styles.ts'
import {Form} from 'reactstrap'
import Chip from '@components/ui/Chip'
import InputText from '@components/ui/InputText'
import {capitalize} from '@utilities/helpers.ts'

const StepMedication: React.FC = () => {
	const {t} = useTranslation()
	const {medications, setClinicalInfoData, userType} = useBoundStore()
	const [medicationValue, setMedicationValue] = useState<string>('')
	const [invalidInput, setInvalidInput] = useState<boolean>(false)

	const onAddMedication = (value: string) => {
		if (!value) {
			setInvalidInput(true)
		} else if (value.length > 23) {
			setInvalidInput(true)
		} else {
			setClinicalInfoData('medications', [...medications, {value: value}])
			setMedicationValue('')
		}
	}

	const onDeleteMedication = (medication: string) => {
		setClinicalInfoData(
			'medications',
			medications.filter(i => i.value !== medication)
		)
	}

	return (
		<>
			<StepContainer>
				<Title title={t(`${userType}:clinicalInformation:drugs:title`)} />
				<Form>
					<span>{t(`${userType}:clinicalInformation:drugs:subtitle`)}</span>
					<label className={'d-grid'}>
						<div className={`row ${invalidInput ? 'align-items-center' : 'align-items-end'}`}>
							<div className={'col-8'}>
								<InputText
									id={'medication'}
									value={medicationValue}
									onChange={e => {
										setInvalidInput(false)
										setMedicationValue(e.target.value)
									}}
									type="text"
									placeholder={t('commons:form:drug') || ''}
									label={t('commons:form:drug') || ''}
									invalid={invalidInput}
									message={t('errors:medicine_error') || ''}
								/>
							</div>

							<div className={`col-4`}>
								<StyledButtonAddDrug
									onClick={e => {
										e.preventDefault()
										onAddMedication(medicationValue)
									}}
								>
									<p>{t('commons:form:add_drug')}</p>
								</StyledButtonAddDrug>
							</div>
						</div>
					</label>
					<div className={'d-flex flex-wrap gap-3'}>
						{medications.map((medication, index) => (
							<Chip
								className={`chip-${index}`}
								key={index}
								text={capitalize(medication.value)}
								canClose
								handleClose={() => onDeleteMedication(medication.value)}
							/>
						))}
					</div>

					<Alert text={t(`${userType}:clinicalInformation:drugs:info`)} />
				</Form>
			</StepContainer>
			<StepClinicalInfoCta />
		</>
	)
}

export default StepMedication
