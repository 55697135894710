import styled, {css, DefaultTheme} from 'styled-components'
import {QR_TYPES} from '@utilities/constants'
import {hexColorFromBootstrap} from '@utilities/helpers.ts'

type StyledContainerQrCodeProps = {
	theme: DefaultTheme
	codeType: number
}
export const StyledContainerQrCode = styled.div`
	${({theme, codeType}: StyledContainerQrCodeProps) => css`
		padding: ${theme.spacing * 5}px 0;
		gap: ${theme.spacing * 4}px;
		border-radius: ${theme.spacing}px;
		box-shadow: 0 4px 4px 0 #00000026;
		color: ${theme.palette.neutral.white};
		background-color: ${hexColorFromBootstrap(codeType)};

		${codeType === QR_TYPES.white &&
		css`
			border: 1px solid ${theme.palette.neutral[300]};
			color: ${theme.palette.neutral.black};
			border-bottom: 0px;
		`}

		& h4 {
			font-family: 'Roboto Mono', monospace;
		}
	`}
`
