import ReactSelect, {ActionMeta, MenuPlacement, MultiValue, SingleValue, createFilter} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {customStyles, StyledContainer, StyledLabel} from './style.ts'
import {useTheme} from 'styled-components'
import React, {ReactNode, RefCallback, useCallback, useRef} from 'react'
import InputHelpText from 'src/components/ui/Select/InputHelpText'
import {customComponents} from '@components/ui/Select/commons.tsx'
import {DefaultTFuncReturn} from 'i18next'

export type SelectValue = {
	label: string
	value: string
	data?: string
}

export interface SelectProps {
	className?: string
	name?: string
	options: SelectValue[]
	label?: string | DefaultTFuncReturn
	placeholder?: string | DefaultTFuncReturn
	helpText?: string | DefaultTFuncReturn
	readOnly?: boolean
	defaultValue?: SelectValue | []
	isSearchable?: boolean
	isClearable?: boolean
	isCreatable?: boolean
	formatCreateLabel?: (value: string) => ReactNode
	addOptionMessage?: string
	maxItems?: number
	isMulti?: boolean
	closeMenuOnSelect?: boolean
	disabled?: boolean
	menuIsOpen?: boolean
	size?: 'medium' | 'large'
	menuPlacement?: MenuPlacement | undefined
	/**
	 * How to use the onChange based on controlled or uncontrolled select:
	 *
	 * uncontrolled: onChange={(event) => console.log(event)}
	 * controlled multi: onChange={(newValue) => {onChange(newValue as SelectValue[])}}
	 * controlled single: onChange={(newValue) => {onChange([newValue] as SelectValue[])}}
	 */
	onChange?: (
		newValue: SingleValue<SelectValue> | MultiValue<SelectValue> | SelectValue[],
		actionMeta: ActionMeta<SelectValue>
	) => void
	value?: SelectValue | SelectValue[]
	ref?: RefCallback<unknown>
	errorMessage?: string | DefaultTFuncReturn
	invalid?: boolean
	onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
}

const InputSelect = ({
	className,
	name,
	options = [],
	label,
	placeholder,
	helpText,
	defaultValue,
	isSearchable = true,
	isCreatable = false,
	isClearable = false,
	addOptionMessage = '',
	maxItems = 100,
	isMulti = false,
	closeMenuOnSelect = !isMulti,
	disabled,
	menuIsOpen,
	onChange,
	errorMessage,
	value,
	invalid = false,
	onKeyDown,
	...rest
}: SelectProps) => {
	const theme = useTheme()

	// Label for new item creation
	const createLabel = useCallback(
		(value: string) => (
			<span style={{fontSize: 14}}>
				{addOptionMessage}
				<span>{value}</span>
			</span>
		),
		[addOptionMessage]
	)

	const selectProps = {
		options,
		name,
		closeMenuOnSelect,
		isSearchable,
		isCreatable,
		isMulti,
		isDisabled: rest.readOnly || disabled,
		classNamePrefix: isCreatable ? 'creatable_select' : 'select',
		placeholder,
		isClearable,
		createLabel,
		disabled,
		maxItems,
		defaultValue,
		onChange,
		invalid,
		onKeyDown,
		...rest
	}

	const selectComponentProps = {
		formatCreateLabel: createLabel,
		components: customComponents,
		...selectProps,
		styles: customStyles({theme, isError: invalid}),
		autoComplete: 'off'
	}

	const bodyRef = useRef<HTMLLabelElement>(null)

	const scrollToRef = () => {
		if (bodyRef.current) {
			setTimeout(() => {
				bodyRef.current && bodyRef.current.scrollIntoView({behavior: 'smooth'})
			}, 500)
		}
	}

	return (
		<StyledContainer className={className}>
			{label && (
				<StyledLabel htmlFor={name} ref={bodyRef}>
					{label}
				</StyledLabel>
			)}
			{isCreatable ? (
				<CreatableSelect {...selectComponentProps} />
			) : (
				<ReactSelect
					value={value}
					menuIsOpen={menuIsOpen}
					menuPlacement="bottom"
					{...selectComponentProps}
					onMenuOpen={scrollToRef}
					filterOption={createFilter({matchFrom: 'start'})}
				/>
			)}
			<InputHelpText helpText={helpText} errorMessage={errorMessage} invalid={invalid} />
		</StyledContainer>
	)
}

export default InputSelect

InputSelect.displayName = 'InputSelect'
