import {StepContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import BackButton from '@/components/commons/ButtonTypes/BackButton'
import {useNavigate} from 'react-router-dom'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import CommonModal from '@/components/commons/Modal'
import {StyledContainerPrivacy, StyledPrivacyCta} from './styles'
import {StyledFooter} from '@/components/ui/Footer/style'
import {AxiosError, handleGenericError} from '@/utilities/helpers'
import {deleteCard} from '@/services/patient.http'
import {routes} from '@utilities/constants'
import privacy from '@/assets/pdf/privacy-policy.pdf'
import {DownloadIcon} from '@/assets/icons/icons'

const Privacy = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {qrCode} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()

	const [modal, setModal] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const emails = useMemo(() => {
		return cardsData?.settings?.emails_to_data
	}, [cardsData])

	const handleConfirm = () => {
		if (qrCode) {
			handleDeleteCard(qrCode)
		}
	}

	const navigateBack = () => {
		navigate(-1)
	}

	const handleReset = () => {
		resetStores()
		navigate(routes.ONBOARDING.path)
	}

	const handleDeleteCard = async (qrCode: string) => {
		try {
			const response = await deleteCard(qrCode)
			if (response.status === 204) {
				handleReset()
			}
		} catch (error) {
			setModal(false)
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleDownload = () => {
		const pdfUrl = privacy
		const link = document.createElement('a')
		link.href = pdfUrl
		link.download = 'Informazioni Privacy_wab app triage.pdf'
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<>
			<div className={'flex-row d-flex justify-content-start p-3 pt-4 bg-white'}>
				<BackButton text={'commons:back'} onClick={navigateBack} className={'bg-white'} />
			</div>
			<StepContainer className={'h-100 rounded-0 border-0'}>
				<div className={'flex-column d-flex justify-content-start align-items-start pt-2'}>
					<h1>{t('commons:privacy:title')}</h1>
					<StyledContainerPrivacy>
						{qrCode &&
							emails?.map(i => {
								return (
									<a
										href={`mailto:${i.recipient}?subject=${encodeURIComponent(i.title)}${
											' : ' + qrCode
										}&body=${encodeURIComponent(i.message)}`}
										key={i.id}
									>
										{i.title}
									</a>
								)
							})}
						<StyledPrivacyCta onClick={handleDownload}>
							<h5 className="text-primary">{t('commons:welcome_page:privacy')}</h5>
							<DownloadIcon />
						</StyledPrivacyCta>
					</StyledContainerPrivacy>
				</div>
				<CommonModal
					title={t('commons:delete_data') || ''}
					modal={modal}
					setModal={setModal}
					centered
					confirm={t('commons:confirm') || ''}
					isLoading={isLoading}
					onConfirm={handleConfirm}
					cancel={t('commons:cancel') || ''}
					onCancel={() => setModal(false)}
				/>
			</StepContainer>
			{qrCode && (
				<StyledFooter>
					<StyledButtonStart
						color="secondary"
						outline={false}
						type="button"
						onClick={() => {
							setModal(true)
						}}
					>
						{t('commons:privacy:delete')}
					</StyledButtonStart>
				</StyledFooter>
			)}
		</>
	)
}

export default Privacy
function resetStores() {
	throw new Error('Function not implemented.')
}
