import React, {useMemo, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer, StyledButtonContainer, StyledContainerCheckboxStep8, StyledLabel} from '../styles.ts'
import Title from '@components/ui/Title'
import Input from 'src/components/ui/InputText'
import Radio from '@components/ui/Radio'
import InputDate from '@/components/ui/InputDate'
import {Form} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import Select, {SelectValue} from '@components/ui/Select'

import {CfBody, postFiscalCodeGetter} from '@services/patient.http.ts'
import {StyledFooter} from '@/components/ui/Footer/style'
import {
	AxiosError,
	handleGenericError,
	minDate,
	remapSelectOptions,
	seventeenYearsAgo,
	useCitiesList
} from '@utilities/helpers.ts'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import NextButton from '@components/commons/ButtonTypes/NextButton.tsx'
import {getCities} from '@/services/commons.http.ts'

interface Props {
	setIsOpen: (e: boolean) => void
}

const StepCreateCF: React.FC<Props> = ({setIsOpen}) => {
	const {subStep, patient, userType, isInReview, setGeneralPatientData, setPatientData} = useBoundStore()

	const {firstName, lastName, birthDate, gender, birthProvince, birthPlace} = patient
	const {provinces, cities, setCommonsData, cardsData} = useCommonsStore()
	const {t} = useTranslation()

	const formRef = useRef<HTMLFormElement>(null)
	const [isLoading, setIsLoading] = useState(false)
	const genderList = useMemo(() => {
		return cardsData?.patient_data?.genders
	}, [cardsData])
	const citiesList = useCitiesList(cities)

	const handleCities = async (province: string) => {
		try {
			const {data} = await getCities(province)
			setCommonsData('cities', data)
			setPatientData('birthPlace', {label: '', value: ''})
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const handlePostCF = async () => {
		const body: CfBody = {
			first_name: firstName,
			last_name: lastName,
			gender: gender?.id,
			birth_place: birthPlace?.data || '',
			birth_date: birthDate
		}
		setIsLoading(true)
		try {
			const {data} = await postFiscalCodeGetter(body)
			const code = data?.code
			if (code) {
				setPatientData('cf', code)
				if (isInReview) {
					if (subStep === 6) {
						setGeneralPatientData('isInDrawer', false)
						setGeneralPatientData('step', 12)
					} else {
						setIsOpen(false)
						setGeneralPatientData('subStep', 6)
					}
				} else {
					setIsOpen(false)
					setPatientData('isCalculatedCF', true)
				}
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (
			firstName !== '' &&
			lastName !== '' &&
			gender &&
			birthPlace?.label?.trim() !== '' &&
			birthProvince &&
			birthDate
		) {
			setCommonsData('showToast', {
				isVisible: false
			})
			handlePostCF()
		} else {
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:empty_field`) || '',
				status: 'danger'
			})
		}
	}

	const handleOutsideButtonClick = () => {
		if (formRef?.current) {
			formRef?.current.dispatchEvent(new Event('submit', {bubbles: true}))
		}
	}

	return (
		<>
			<StepContainer notAnimate>
				<Title
					title={t(`${userType}:patientData:italianResidence:cf:create_cf:title`)}
					subTitle={t(`${userType}:patientData:italianResidence:cf:create_cf:subtitle`)}
				/>
				<Form innerRef={formRef} onSubmit={handleSubmit} className={'h-100'}>
					<Input
						label={t(`commons:form:name`)}
						placeholder={t(`commons:form:name`) || ''}
						type="text"
						onChange={e => {
							setPatientData('firstName', e.target.value)
						}}
						value={firstName}
						invalid={firstName?.trim() === ''}
						message={t(`errors:name_error`)}
					/>
					<Input
						label={t(`commons:form:last_name`)}
						placeholder={t(`commons:form:last_name`) || ''}
						type="text"
						onChange={e => {
							setPatientData('lastName', e.target.value)
						}}
						value={lastName}
						invalid={lastName?.trim() === ''}
						message={t(`errors:last_name_error`)}
					/>

					<InputDate
						label={t(`commons:form:birth_date`)}
						placeholder={t(`commons:form:birth_date`) || ''}
						id="exampleDate"
						name="date"
						type="date"
						onChange={e => setPatientData('birthDate', e.target.value)}
						defaultValue={birthDate}
						min={minDate()}
						max={seventeenYearsAgo()}
					/>

					<div>
						<StyledLabel className={'ps-1 custom-label'}>{t('commons:form:gender')}</StyledLabel>
						<StyledContainerCheckboxStep8>
							{genderList?.map(i => {
								return (
									<Radio
										key={i.id}
										label={i.name || ''}
										name={i.id.toString() || ''}
										onChange={e => setPatientData('gender', {name: e.target.name, id: i.id})}
										check={gender?.id === i.id}
									/>
								)
							})}
						</StyledContainerCheckboxStep8>
					</div>

					<div className={'d-grid gap-4'}>
						<Select
							options={remapSelectOptions(provinces)}
							label={t('commons:form:province_of_birth')}
							placeholder={t('commons:form:placeholder:province_of_birth')}
							onChange={newValue => {
								const value = newValue as SelectValue
								setPatientData('birthProvince', value)
								if (value?.label) {
									handleCities(value.label)
								}
							}}
							value={birthProvince}
						/>

						<Select
							options={citiesList || []}
							label={t('commons:form:birthplace')}
							placeholder={t('commons:form:placeholder:birthplace')}
							onChange={newValue => {
								setPatientData('birthPlace', newValue as SelectValue)
							}}
							value={birthPlace || undefined}
							disabled={!citiesList.length}
						/>
					</div>
				</Form>
			</StepContainer>
			<StyledFooter>
				<StyledButtonContainer>
					<BackButton
						onClick={() => {
							setIsOpen(false)
						}}
						text={'commons:cancel'}
					/>
					<NextButton onClick={handleOutsideButtonClick} isLoading={isLoading} />
				</StyledButtonContainer>
			</StyledFooter>
		</>
	)
}

export default StepCreateCF
