import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import Title from '@components/ui/Title'
import QRContainer from '@components/commons/QRContainer/index.tsx'
import {StepContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import Alert from '@components/ui/Alert'
import Footer from '@components/ui/Footer'
import {useNavigate} from 'react-router-dom'
import {PHASES, routes} from '@utilities/constants'
import {useEffect, useMemo, useState} from 'react'
import Spinner from '@components/ui/Spinner'
import dayjs from 'dayjs'
import {getQrColor} from '@services/patient.http.ts'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const CodeAssigned = () => {
	const {userType, selectedSymptoms, colorCode, gipseUpdated, qrCode} = useBoundStore()
	const {t} = useTranslation()
	const navigate = useNavigate()
	const [footerShown, setFooterShown] = useState(false)
	const [showFooter, setShowFooter] = useState(false)
	const {setCommonsData, cardsData} = useCommonsStore()

	const triageCodes = useMemo(() => {
		return cardsData?.triage_codes?.find(i => i.value === colorCode)
	}, [cardsData])

	useEffect(() => {
		if (gipseUpdated !== null) {
			const gipseUpdatedDate = dayjs(gipseUpdated).toDate()
			const halfTriageMinutes = (triageCodes?.minutes || 0) / 2
			const timeoutInMilliseconds = dayjs(gipseUpdatedDate)
				.add(halfTriageMinutes, 'minute')
				.diff(dayjs(), 'millisecond')

			const timeoutId = setTimeout(() => {
				setShowFooter(true)
				localStorage.setItem('footerShown', 'true')
			}, timeoutInMilliseconds)

			return () => clearTimeout(timeoutId)
		}
	}, [gipseUpdated, triageCodes])

	const handleHowIsItGoing = () => {
		setCommonsData('isNextAnimation', true)
		navigate(`${routes.FEEDBACK.path}`)
	}

	useEffect(() => {
		const footerShown = localStorage.getItem('footerShown')
		if (footerShown === 'true') {
			setFooterShown(true)
		}
	}, [])

	useEffect(() => {
		if (qrCode) {
			triageCompleted(qrCode)
		}
	}, [])
	const triageCompleted = async (qr: string) => {
		try {
			const {data} = await getQrColor(qr)
			if (data.completed === true) {
				navigate(`${routes.TRIAGE_CONCLUDED.path}`)
				setCommonsData('userPhase', PHASES.triageConcluded)
			} else {
				return
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	return (
		<>
			<StepContainer className={'h-100'}>
				<Title
					title={t(`${userType}:waiting:assignedCode:title`)}
					subTitle={t(`${userType}:waiting:assignedCode:subtitle`)}
					isCentered
				/>

				{colorCode ? <QRContainer codeType={colorCode} isColorful /> : <Spinner size={30} />}

				<div className={'mb-5'}>
					<Alert text={t(`${userType}:waiting:assignedCode:subtitle2`)} status={'secondary'} />
				</div>
			</StepContainer>
			{showFooter && selectedSymptoms.length > 0 && !footerShown && (
				<Footer>
					<StyledButtonStart color="primary" outline={false} type="button" onClick={handleHowIsItGoing}>
						<p>{t(`${userType}:waiting:howIsItGoing:title`)}</p>
					</StyledButtonStart>
				</Footer>
			)}
		</>
	)
}

export default CodeAssigned
