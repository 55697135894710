import {Outlet} from 'react-router-dom'
import React from 'react'
import Header from '@components/commons/Header'
import {StyledMain} from '../styles'
import SubHeader from '@components/commons/SubHeader'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {StyledHeaderDivider} from '@/components/commons/Header/style'
import Toast from '@components/ui/Toast'
import Landing from '@/pages/landing/Landing'

const PublicLayout: React.FC = () => {
	const {step} = useBoundStore()
	const {showToast} = useCommonsStore()
	const {isVisible, text, status} = showToast

	const progressValue = () => {
		if (step === 3) return 2
		if (step <= 6) return (step - 4) * 5 + 5
		if (step === 7) return 25
		if (step >= 8) return (step - 8) * 5 + 30
		return 0
	}

	return (
		<>
			<Landing />
			{isVisible && <Toast text={text} status={status} isVisible={isVisible} />}
			<StyledMain>
				<Header hasAvatar={step >= 1} />
				<StyledHeaderDivider />
				{step >= 3 && <SubHeader progressValue={progressValue()} />}
				<Outlet />
			</StyledMain>
		</>
	)
}

export default PublicLayout
