import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './translations/i18n.ts'
import {QueryClientProvider} from '@tanstack/react-query'
import {queryClient} from '@/queryClient.ts'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<QueryClientProvider client={queryClient}>
		<App />
	</QueryClientProvider>
)
