import {StepContainer} from '@pages/onBoarding/styles.ts'
import BackButton from '@/components/commons/ButtonTypes/BackButton'
import {useNavigate} from 'react-router-dom'

import {StyledContainerTerms} from './styles'
import {useEffect, useState} from 'react'
import {getIubendaContent} from '@services/commons.http.ts'

const Terms = () => {
	const navigate = useNavigate()
	const [iubendaContent, setIubendaContent] = useState<string>('')

	const navigateBack = () => {
		navigate(-1)
	}

	const iubendaResponse = async () => {
		try {
			const {data} = await getIubendaContent()
			setIubendaContent(data.content)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		iubendaResponse()
	}, [])

	return (
		<>
			<div className={'flex-column d-flex justify-content-start align-items-start p-3 pt-4 bg-white'}>
				<BackButton text={'commons:back'} onClick={navigateBack} className={'bg-white'} />
			</div>

			<StepContainer className={'h-100 rounded-0 border-0'}>
				<div className={'flex-column d-flex justify-content-start align-items-start pt-2'}>
					{/*<h1>{t('commons:terms:title')}</h1>*/}
					<StyledContainerTerms>
						<div dangerouslySetInnerHTML={{__html: iubendaContent}} />
					</StyledContainerTerms>
				</div>
			</StepContainer>
		</>
	)
}

export default Terms
