import {useBoundStore} from '@store/boundStore.ts'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'

const SharedRoute = () => {
	const {qrCode} = useBoundStore()

	return qrCode ? <PrivateRoute /> : <PublicRoute />
}

export default SharedRoute
