import {Outlet} from 'react-router-dom'
import React from 'react'
import {StyledMain} from '../styles'
import Landing from '@/pages/landing/Landing'

const SharedLayout: React.FC = () => {
	return (
		<>
			<Landing />
			<StyledMain>
				<Outlet />
			</StyledMain>
		</>
	)
}

export default SharedLayout
