import {StyledContainerInfo} from '@pages/triageConcluded/styles.ts'
import {CheckCircleIcon} from '@assets/icons/icons.tsx'
import {useTranslation} from 'react-i18next'
import {useBoundStore} from '@store/boundStore.ts'
import {useEffect} from 'react'

const TriageConcluded = () => {
	const {t} = useTranslation()
	const {userType} = useBoundStore()

	useEffect(() => {
		localStorage.clear()
	}, [])

	return (
		<div className={'bg-white h-100 d-flex'}>
			<StyledContainerInfo>
				<CheckCircleIcon />
				<h4>{t(`${userType}:waiting:triageConcluded:title`)}</h4>
				<p>{t(`${userType}:waiting:triageConcluded:subtitle`)}</p>
			</StyledContainerInfo>
		</div>
	)
}

export default TriageConcluded
