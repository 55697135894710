import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import React, {useEffect} from 'react'
import {StyledContainerReceivedData} from '../clinicalInfo/style'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {PHASES, QR_TYPES, routes} from '@utilities/constants'
import QRContainer from '@components/commons/QRContainer/index.tsx'
import IconsStepsStatus from '@components/commons/IconsStepsStatus'
import {getQrColor} from '@services/patient.http.ts'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const Finished: React.FC = () => {
	const {userType, qrCode, setGeneralPatientData} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()
	const navigate = useNavigate()

	const handleGetColor = async (qr: string) => {
		try {
			const {data} = await getQrColor(qr)
			if (data?.color?.value && qrCode) {
				setGeneralPatientData('colorCode', data?.color?.value)
				navigate(`${routes.CODEASSIGNED.path.replace(':id', qrCode)}`)
				setCommonsData('userPhase', PHASES.other)
				setGeneralPatientData('gipseUpdated', data?.gipse_updated_at)
			} else {
				return
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	useEffect(() => {
		if (!qrCode) return
		handleGetColor(qrCode)
	}, [])

	return (
		<>
			<div className={'overflow-scroll'} style={{height: '100%'}}>
				<StyledContainerReceivedData>
					<div className={'d-flex flex-column justify-content-center align-items-center gap-3'}>
						<div className={'d-flex gap-2 align-items-center'}>
							<IconsStepsStatus step={3} />
						</div>

						<div className={'d-flex flex-column justify-content-center align-items-center gap-2'}>
							<h4>{t(`${userType}:symptoms:dataSent:title`)}</h4>
							<p className={'text-center'}>{t(`${userType}:symptoms:dataSent:subtitle`)}</p>
							<p className={'text-center'} style={{fontWeight: '600'}}>
								{t(`${userType}:symptoms:dataSent:info`)}
								<Trans
									i18nKey={t('commons:link')}
									components={{a: <a />}}
									className={'text-decoration-underline text-primary fw-600 text-center'}
								/>
							</p>
						</div>
					</div>
				</StyledContainerReceivedData>

				<QRContainer isColorful={false} codeType={QR_TYPES.white} canShowIt />
			</div>
		</>
	)
}

export default Finished
