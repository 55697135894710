import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'

const isInstalled = window.matchMedia('(display-mode: standalone)').matches

const ResetStyles = createGlobalStyle`
  * {
  '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
  ' scrollbar-width': 'none' /* for Firefox */,
  }

  html {
    height: 100%;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: ${palette.light.neutral[500]};
    background-color: ${palette.light.neutral['25']};
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  }

  html {
  touch-action: pan-y;
  touch-zooming: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

  body {
    height: 100%;
    user-select: none!important;
    overflow-x: hidden!important;
    overflow-y: auto;
    padding-bottom: ${isInstalled ? 10 : 0}px;
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }

  label, input, textarea, select, button {
    font-family: inherit;
  }

  textarea {
    overflow-wrap: break-word;
  }

  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol {
    list-style: none;
  }
  li{
    list-style-type: square;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }

  form {
    margin-bottom: 14px;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  button:focus:not(.focus--mouse), .form-check [type=checkbox]:focus + label, .form-check [type=radio]:focus + label, .toggles label input[type=checkbox]:focus + .lever,
  input:focus:not(.focus--mouse), .form-check [type=checkbox]:focus + label, .form-check [type=radio]:focus + label, .toggles label input[type=checkbox]:focus + .lever,
  textarea:focus:not(.focus--mouse), .form-check [type=checkbox]:focus + label, .form-check [type=radio]:focus + label, .toggles label input[type=checkbox]:focus + .lever,
  select:focus:not(.focus--mouse), .form-check [type=checkbox]:focus + label, .form-check [type=radio]:focus + label, .toggles label input[type=checkbox]:focus + .lever,
  div:focus:not(.focus--mouse) {
    outline: none !important;
    box-shadow: none !important;
    border-color: inherit !important;
  }

  input[type=date] {
    text-align: left;
  }

  .fw-600 {
    font-weight: 600;
  }

  /* Change Autocomplete styles in Chrome*/
  /* Change Autocomplete styles in Safari*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px #fafafa inset !important;
    background-color: #fafafa !important;
    background-clip: content-box !important;
  }

  input::-webkit-date-and-time-value {
    text-align: left!important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`

export default withTheme(ResetStyles)
