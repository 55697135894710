import {StateCreator} from 'zustand'
import {CommonsAction, CommonsDataState} from '@/types/commonType.ts'

export const initialState = {
	provinces: [],
	cities: [],
	residenceCities: [],
	domicileCities: [],
	cardsData: undefined,
	showToast: {
		isVisible: false
	},
	userPhase: '',
	isNextAnimation: true
} satisfies CommonsDataState

export const createCommonsDataSlice: StateCreator<CommonsDataState & CommonsAction> = set => ({
	...initialState,
	setCommonsData: (key, value) => set(() => ({[key]: value}))
})
