import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {useEffect} from 'react'

const PublicRoute = () => {
	const navigate = useNavigate()

	const {step} = useBoundStore()
	const {cardsData} = useCommonsStore()

	useEffect(() => {
		if (step > 2 && !cardsData) {
			setTimeout(() => {
				navigate(routes.NOTFOUND.path)
			}, 1500)
		}
	}, [step, cardsData])

	const {qrCode} = useBoundStore()

	return qrCode ? <Navigate to={routes.CLINICALINFO.path} /> : <Outlet />
}

export default PublicRoute
