import {StyledContainerQrCode} from '@components/commons/QRContainer/styles.ts'
import {QR_TYPES, routes} from '@utilities/constants'
import QRCode from 'react-qr-code'
import {useTranslation} from 'react-i18next'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {InfoIcon} from '@assets/icons/icons.tsx'
import {hexColorFromBootstrap} from '@utilities/helpers.ts'
import {useTheme} from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {useMemo} from 'react'

interface QRContainerProps {
	isColorful: boolean
	codeType: number
	canShowIt?: boolean
	isEntireScreen?: boolean
}

const QRContainer = ({isColorful, codeType, canShowIt = false, isEntireScreen = false}: QRContainerProps) => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {qrCode, userType} = useBoundStore()
	const {cardsData} = useCommonsStore()
	const theme = useTheme()

	const triageCodes = useMemo(() => {
		return cardsData?.triage_codes
	}, [cardsData])

	const colorBlackWhenCodeIsWhite =
		codeType === QR_TYPES.white ? theme.palette.neutral.black : theme.palette.neutral.white

	const classToRender = isColorful
		? `d-flex flex-column justify-content-start align-items-center`
		: isEntireScreen
		? 'primary-bg-c1 d-flex flex-column justify-content-center align-items-center gap-3 p-4 border-top h-100'
		: 'primary-bg-c1 d-flex flex-column justify-content-start align-items-center gap-3 p-4 border-top'

	return (
		<>
			<StyledContainerQrCode className={classToRender} codeType={codeType}>
				{!isColorful && (
					<p className={'text-center text-secondary'}>{t(`${userType}:patientData:dataSent:id`)}</p>
				)}

				<h4 className={'fw-bold'}>{qrCode}</h4>

				<div className={'bg-white'}>
					<QRCode
						className={'m-2'}
						value={qrCode || ''}
						size={180}
						bgColor={hexColorFromBootstrap(codeType)}
						fgColor={colorBlackWhenCodeIsWhite}
					/>
				</div>

				{isColorful && (
					<>
						<h3>{triageCodes?.find(i => i.value === codeType)?.name}</h3>
						<h5 className="p-0 px-4 text-center">
							{triageCodes?.find(i => i.value === codeType)?.wait_time_description}
						</h5>

						<div
							className={'d-flex justify-content-start align-items-center gap-2'}
							onClick={() => {
								navigate(`${routes.CODE.path}`)
							}}
						>
							<div className={'d-flex justify-content-start align-items-center'}>
								<InfoIcon fill={colorBlackWhenCodeIsWhite} />
							</div>
							<p className={'text-decoration-underline text-uppercase'}>
								{t('commons:code:triage_code')}
							</p>
						</div>
					</>
				)}
				{!isColorful && canShowIt && (
					<p className={'text-center text-secondary w-75'}>
						{t(`${userType}:clinical_information:info`) || ''}
					</p>
				)}
			</StyledContainerQrCode>
		</>
	)
}

export default QRContainer
