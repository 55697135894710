import styled from 'styled-components'

export const HeaderWrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 56px;
	z-index: 999;
	@media only screen and (min-width: 1030px) {
		display: none;
	}
`
export const StyledHeaderDivider = styled.div`
	height: 56px;
	min-height: 56px;
	width: 100%;
`

export const StyledHeaderWrapperLight = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 56px;
`
export const StyledHeaderSpinner = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	height: calc(100vh - 56px);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 16px;
	z-index: 999;
	background: #06c;
`
